// @import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Montserrat:wght@300,400,500,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carattere&family=Montserrat:ital,wght@0,100;0,200;1,100&display=swap');
@import '~ng-wizard/themes/ng_wizard.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_circles.min.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

*{
  font-family: 'Montserrat', sans-serif;
}

body {
  color: #060443;
  background-color:#f5f5f5;
  font-family: 'Montserrat', sans-serif !important;
}

a {
  color: #96ca5e;
}

a:hover {
  color: #a5adb5;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  font-family: 'Montserrat', sans-serif !important;
}

p {
  line-height: 1.1 !important;
}

font-colorGreen {
  color:#96ca5e;
}

font-colorBlue {
  color:#040641;
}

.ng-wizard-theme-circles>ul.step-anchor {
  background: transparent !important;
}

.ng-wizard-theme-circles>ul.step-anchor>li {
  border: none;
  margin: 0 auto;
  z-index: 98;
}

.ng-wizard-theme-circles>ul.step-anchor>li>a {
  border: 6px solid #a5adb5;
  background: #eee;
  font-weight: 700;
  color: #a5adb5;
  line-height: 0.2 !important;
  box-shadow: inset 0 0 0 0 rgba(0,0,0,0) !important;
}

.ng-wizard-theme-circles>ul.step-anchor>li.active>a {
  border-width: 6px;
  border-color:#5cb85c;
  color: #5cb85c;
  background: #fff;
  line-height: 0.4 !important;
  font-weight: 700;
}

.ng-wizard-theme-circles>ul.step-anchor>li.done>a {
  border-width: 6px;
  border-color:#5cb85c;
  color: #fff;
  background: #5cb85c;
  line-height: 0.4 !important;
  font-weight: 500;
  box-shadow: inset 0 0 0 0 rgba(0,0,0,0) !important;
}

.ng-wizard-theme-circles>ul.step-anchor>li>a>small {
  color:#88c249;
  font-weight: 700;
  display: none;
}

.ng-wizard-theme-circles>ul.step-anchor>li.active>a>small {
  color:#5cb85c;
  font-weight: 700;
  display: block;
}

// line
.ng-wizard-theme-circles>ul.step-anchor:before {
  left: 10%;
  width: 80% !important;
  background-color: #a5adb5 !important;
}

.ng-wizard-theme-circles>ul.step-anchor>li>a {
  width: 64px !important;
  height: 64px !important;
  line-height: 0.6 !important;
}


.ng-wizard-theme-circles>ul.step-anchor>li>a>small {
  color: #ccc;
  font-weight: 500;
}

.ng-wizard-theme-circles>ul.step-anchor {
  margin-bottom: 45px !important;
}

.btn-group.mr-2.ng-wizard-btn-group {
  position: absolute !important;
  top: 89% !important;
  right: 170px;
}

button.btn.btn-secondary.ng-wizard-btn-prev {
  background-color: #060443;
  color: #fff;
  font-size: 18px;
  padding: 10px 22px 10px 22px;
  font-weight: 600;
  letter-spacing: 1px;
  border: 0;
}

button.btn.btn-secondary.ng-wizard-btn-prev.disabled {
  display: none !important;
}

@media screen and (max-width: 992px){
  .btn-group.mr-2.ng-wizard-btn-group {
    position: absolute !important;
    top: 98.5% !important;
    right: 170px;
  }
  .ng-wizard-theme-circles>ul.step-anchor>li.active>a>small {
    font-size: 13px;
  }
  .ng-wizard-theme-circles>ul.step-anchor>li>a>small {
    font-size: 13px;
  }
}

.mat-button, .mat-icon-button, .mat-stroked-button{
    color: #040641 !important;
  }


.custom-dialog >  mat-dialog-container {
    background:#88c249;
}

.modal-header {
  border-bottom:0px solid #dee2e6 !important;
}

.modal-footer{
  border-top:0px solid #dee2e6 !important;
}

.modal-content {
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  border-width: 4px;
  border-color: #ececec;

}

.color-spinner{
  color: #88c249 !important;
}

.text-vehiculo{
  font-size: 16px;
  font-weight: 600;
  color: #88c249;
}

.a-text-vehiculo{
  color: #0056b3 !important;
  text-decoration: underline #0056b3 !important;
}

.img-cotizacion{
  max-width: 50% !important;
}

.table-semi-dark {
  background-color: #eeeeee !important;
}

.banner-city {
  /*background-image: url(./assets/img/01_cotiza_Final.png);*/
  min-height: 280px;
  background-position: center bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.banner-city .autos {
  position: absolute;
  left:0;
  bottom: 10px;

     -moz-animation: leftToright 15s linear infinite;
  -webkit-animation: leftToright 15s linear infinite;
       -o-animation: leftToright 15s linear infinite;
          animation: leftToright 15s linear infinite;
}

@media query {

}

@media screen and (max-width: 400px) {
  .banner-city {
    background-size: 380% auto;
  }
}

@keyframes leftToright {
  0% { left: -100%; opacity: 1; }
  98% { left: 100%; opacity: 1; }
  99% { left: 100%; opacity: 0; }
  100% { left: 0; opacity: 0; }
}

span.help-block {
  font-size: 12px;
  clear: both;
  display: block;
}

.auto-bg {
  background-image: url('./assets/img/bg-isiix-autos.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="password"],
select {
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 2px solid #a5adb5 !important;
  border-radius: 0 !important;
  height: 30px;
  padding: 2px .75rem 0px .75rem;
  /* padding: 13px 0 !important; */
}

label,
.form-group label {
  font-weight: 400;
  color: #060443;
  font-size: 14px;
}

.w-75 {

}

.btn {
  font-weight: bold !important;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  margin-top: 0px;
}

.btn-isiix {
  border-radius: 18px;
  box-shadow: 4px 4px 12px rgba(0,0,0,0.14), -4px -4px 12px rgba(255,255,255,1);
  border: 4px #ececec solid;
}

/*.btn-isiix:hover {
  background-color: #201545;
  border: 4px #88c249 solid;
  color: white;
}*/

.btn-success {
  color: #201545 !important;
}

.btn-success {
  background-color: #96ca5e;
  border-color: #96ca5e;
}
.btn-success:hover {
  background-color: #c3e1a2;
  border-color: #c3e1a2;
}

.btn-rounded {
  border-radius: 34px;
}

.ng-wizard-theme-circles .step-content {
  background: transparent !important;
}

.verde {
  color: #96ca5e;
}
.azul {
  color: #040641;
}



.recorrido {
  margin-bottom: 0px;
}
.recorrido .recorrido {margin-bottom: 0;}
.recorrido div[class^="paso"] {
  width: 64px;
  height: 64px;
  background-color: #a5adb5;
  border-radius: 50%;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 38%;
  margin: 0 50px;
  position: relative;
}

.recorrido div[class^="paso"].activo {
  background-size: 50%;
}

.recorrido div[class^="paso"] a {
  width: 100%;
  height: 100%;
  display: block;
  pointer-events: none;
}
.recorrido div[class^="paso"].activo a {
  pointer-events: all;
}
.recorrido div.paso1 {
  background-image: url(./assets/img/icono1.png);
}
.recorrido div.paso2 {
  background-image: url(./assets/img/icono2.png);
}
.recorrido div.paso3 {
  background-image: url(./assets/img/icono3.png);
}
.recorrido div.paso4 {
  background-image: url(./assets/img/icono4.png);
}
.recorrido div.paso5 {
  background-image: url(./assets/img/icono5.png);
}

/* .recorrido div[class^="paso"]:after {
  content: " ";
  width: 53px;
  height: 3px;
  background-color: #a5adb5;
  display: block;
  position: absolute;
  top: 45px;
  right: -53px;
} */
.recorrido div[class^="paso"]:before {
  content: " ";
  width: 230%;
  height: 3px;
  background-color: #a5adb5;
  display: block;
  position: absolute;
  top: 32px;
  left: -200%;
  z-index: -1;
}

.recorrido div[class^="paso"].activo:before {
  top: 26px;
}

.recorrido div[class^="paso"].complete:before {
  top: 25px;
}
/* .recorrido div[class^="paso"]:last-child:after {
  content: none !important;
} */
.recorrido div[class^="paso"]:first-child:before {
  content: none !important;
}

.recorrido div[class^="paso"].activo {
  background-color: white !important;
  border: 6px #88c249 solid;
}

.recorrido div.paso1.activo {
  background-image: url(./assets/img/icono1-activo.png);
}

.recorrido div.paso2.activo {
  background-image: url(./assets/img/icono2-activo.png);
}

.recorrido div.paso3.activo {
  background-image: url(./assets/img/icono3-activo.png);
}

.recorrido div.paso4.activo {
  background-image: url(./assets/img/icono4-activo.png);
}

.recorrido div.paso5.activo {
  background-image: url(./assets/img/icono5-activo.png);
}

.recorrido div[class^="paso"].complete {
  background-color: #88c249 !important;
  border: 6px #fafafa solid;
  background-image: url('/assets/img/icono-check.png');
}
/*
.recorrido div[class^="paso"].activo:after {
  background-color: #040641;
}*/
.recorrido div[class^="paso"].activo:before {
  background-color: #88c249;
}
.recorrido div[class^="paso"].activo:last-child:after {
  background-color: #a5adb5 !important;
}


.h1Banner{
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    line-height: 36px;
    font-weight: 400 !important;
}

.h2Banner {
    font-size: 21px;
    font-weight: 400;
    line-height: 1;
}

.box {
  user-select: none;

  label {
    // position: absolute;
    z-index: 10;
    // padding-right: 40px;
    cursor: pointer;
    margin-left: 12px;
    margin-top: 6px;
  }
  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    &:checked {
        ~ .check {
            border-color: #96ca5e;
            box-shadow: 0px 0px 0px 15px #96ca5e inset;
            &::after {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
  }
  .check {
      width: 30px;
      height: 30px;
      float: left;
      background-color: #FFF;
      border: 2px solid #96ca5e;
      box-shadow: 0px 0px 0px 0px #96ca5e inset;
      transition: all 0.15s cubic-bezier(0, 1.05, 0.72, 1.07);
      border-radius: 8px;
  }
}

// NG-SELECT Override

.ng-select .ng-select-container {
  background-color: transparent !important;
  border: 0 none !important;
  color: white !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  color: white !important;
}


.ng-select > div {
    z-index: 22;
}

/*Template de colores*/
button#button-body{
  background: linear-gradient(135deg, #2a3e7e, #455382) !important;
  box-shadow: #2a3e7e 0px 2px 16px !important;
}
.btn-success {
  background-color: #2a3e7e !important;
  border-color: #2a3e7e !important;
  color: white !important;
}
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem #2a3e7e !important; 
}
.btn-success:hover {
  background-color: #3c4a76 !important;
  border-color: #3c4a76 !important;
  color: white !important;
}

.btn-outline-success {
  color: #2a3e7e !important;
  border-color: #2a3e7e !important;
}
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem #2a3e7e !important; 
}
.btn-outline-success:hover {
  background-color: #3c4a76 !important;
  border-color: #3c4a76 !important;
  color: white !important;
}

.btn-outline-success:active {
  background-color: #3c4a76 !important;
  border-color: #3c4a76 !important;
  color: white !important;
}


.btn-outline-success:before {
  background-color: #3c4a76 !important;
  border-color: #3c4a76 !important;
  color: white !important;
}